<template>
  <v-row v-if="languages && editedItem">
    <v-col
      cols="12"
      sm="12"
      md="6"
      style="padding:15px 20px;"
    >
    <translate
      v-model="editedItem.Title"
      label="T�tulo"
      :languages="languages"
      @input="handleChange"
      type="text"
    />
      <v-text-field
        v-show="false"
        v-model="editedItem.lmu"
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
    >
      <v-text-field
        v-model="editedItem.Color"
        @input="handleChange"
        label="Color"
        hide-details
        outlined
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
    >
      <v-checkbox
        v-model="editedItem.SelectableActivities"
        label="Actividades seleccionables"
        hide-details
        outlined
        @change="handleChange"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
    >
      <v-checkbox
        v-model="editedItem.Show"
        label="Vista de show"
        hide-details
        outlined
        @change="handleChange"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="4"
      style="padding: 20px;"
    >
      <small>Menu:</small>
      <div style="padding-top:20px;">
        <select-image 
          :key="editedItem.lmu"
          :image="editedItem.Image"
          :onChange="handleChangeImage"
          :locale="locale"
          :folder="workspaceID"
        />
      </div>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="4"
      style="padding: 20px;"
    >
      <small>Icono:</small>
      <div style="padding-top:20px;">
        <select-image 
          :key="editedItem.lmu"
          :image="editedItem.Icon"
          :onChange="handleChangeIcon"
          :locale="locale"
          :folder="workspaceID"
        />
      </div>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="4"
      style="padding: 20px;"
    >
      <small>Icono coloreado:</small>
      <div style="padding-top:20px;">
        <select-image 
          :key="editedItem.lmu"
          :image="editedItem.IconColored"
          :onChange="handleChangeIconColored"
          :locale="locale"
          :folder="workspaceID"
        />
      </div>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="12"
    >
      <categories
        v-if="!loading"
        :editedItem="editedItem.Categories"
        :onChange="handleChangeCategories"
        :workspaceID="workspaceID"
        :locale="locale"
        :languages="languages"
      />
    </v-col>
  </v-row>
</template>
<script>
import api from '@/services/api'
import Translate from '@/components/Translate'
import SelectImage from '@/components/selectImage/Index'
import Categories from './Categories'
//import StyleForm from './StyleForm'
export default {
  components: {
    Categories,
    Translate,
    SelectImage,
    //FormFields,
    //StyleForm,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    editedID: {
      type: String,
      default: null,
    },
    onChange: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: true,
    editedItem: null,
    languages: null,
  }),
  computed: {
    /*
    languages () {
      return ['es', 'en', 'fr', 'de', 'ca']
    },*/
  },
  watch: {
    'editedItem.ID' () {
      this.prepareData()
      this.handleGetData()
    },
  },
  mounted () {
    this.prepareData()
    this.handleGetData()
  },
  methods: {
    prepareData () {
      //this.editedItem = JSON.parse(JSON.stringify(this.value))
    },
    async handleGetData () {
      try {
        this.loading = true
        this.languages = await new Promise((resolve, reject) => api.getItem ('dviewcontent', 'v1/system/workspaces/', this.workspaceID)
            .then(response => {   
              resolve(response.Languages ? JSON.parse(response.Languages) : [])
            })
          .catch (err => {
            reject(err)
          }))
        if (this.editedID) {
          api.getItem ('dviewcontent', 'v1/system/content-types/', this.editedID.concat('/activity-barcelo'))
            .then(response => {   
              this.editedItem = JSON.parse(response.ActivityBarceloConfig)
              this.loading = false
            })
        } else {
          this.editedItem = {
            Title: { es: '' },
            Categories: [],
            lmu: new Date().toISOString()
          }
          this.loading = false
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      } 
    },
    handleChangeImage (v) {
      if (!v) return 

      this.editedItem.Image = v.base64
      this.editedItem.newImage = v
      this.editedItem.lmu = new Date().toISOString()
      this.handleChange()
    },
    handleChangeIcon (v) {
      if (!v) return 

      this.editedItem.Icon = v.base64
      this.editedItem.newIcon = v
      this.editedItem.lmu = new Date().toISOString()
      this.handleChange()
    },
    handleChangeIconColored (v) {
      if (!v) return 

      this.editedItem.IconColored = v.base64
      this.editedItem.newIconColored = v
      this.editedItem.lmu = new Date().toISOString()
      this.handleChange()
    },
    handleChangeCategories (v) {
      this.editedItem.Categories = v
      this.handleChange()
    },
    handleChange () {
      this.onChange(this.editedItem)
    },
  },
}
</script>

